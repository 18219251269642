@font-face {
  font-family: 'FavoritStd';
  src: local('FavoritStd'), url('./fonts/FavoritStd-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'FavoritStd';
  src: local('FavoritStd'), url('./fonts/FavoritStd-Bold.ttf') format('truetype');
  font-weight: bold;
}

html, body {
  margin: 0px;
  padding: 0px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'FavoritStd', 'Roboto', sans-serif;
}

h2 {
  text-transform: lowercase;
  font-weight: bold;
  margin-top: -0.3em;
  margin-bottom: 16px;
}

p {
  overflow-wrap: break-word;
  hyphens: auto;
}


#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main-content, .navigation, .footer {
  padding-left: 15px;
  padding-right: 15px;
}

.main-content {
  flex: 2;
  /* padding: 10px 0px; */
  /* padding-left: 0px; */
  padding: 0px;
  /* overflow-y: scroll; */
  overflow: hidden;
  scrollbar-color: grey white;
  border-top: 3px black solid;
  border-bottom: 3px black solid;
}

.split-panels {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

ul {
  list-style: none;
}

li {
  margin-bottom: 10px;
}

a {
  color: black;
}

a:visited {
  color: black;
}

.panel-left, .panel-right {
  overflow-y: scroll;
  scrollbar-color: grey white;
  width: 50%;
  /* height: calc(100% - 6px); */
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  scroll-behavior: smooth;
  box-sizing: border-box; 
}

.panel-left {
  background-color: #0F0;
  border-right: 3px black solid;
  scrollbar-color: black #0F0;
  padding-right: 10%;
  padding-left: 25px;
}

.panel-right {
  border-right: 0px;
}


.intro {
  font-size: 1.1em;
  margin-bottom: 16px;
  margin-top: 16px;
}


.home-page {
  background-color: black;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.home-vid {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: black;
}


.navigation {
  display: flex;
  justify-content: space-between;
}

.nav-links {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.nav-link {
  font-size: 1.5em;
  text-decoration: none;
  margin: 10px;
  color: black;
}

/* .nav-link:visited {
  color: black;
} */

/* .nav-link-active {
  color: #0F0;
} */


.footer {
	text-align: center;
  height: 2em;
  transition: height 0.3s ease-out;
  overflow: hidden;
  /* display: flex; */
  justify-content: space-between;
  padding-bottom: 10px;
  text-align: left;
}

.footer:hover {
  height: 20vh;
}

.footer h2 {
  margin: 10px 0px;
}

.footer-contact, .footer-credits {
  line-height: 0.4em;
  display: inline-block;
  padding-right: 10%;
  vertical-align: top;
}



.radio {
  position: absolute;
  bottom: 10px;
  right: 10px;
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.radio-controls {
  width: 25px;
  height: 25px;
  display: inline;
  margin-left: 10px;
  cursor: pointer;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}

.play-button {
  display: inline;
  /* width: 25px; */
  height: 0.9em;
  /* background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%; */
  /* background-image: url(/media/images/icon-play.png); */
}

.phrases-fullpage {
  background-color: #0F0;
  scrollbar-color: black #0F0;
  overflow-y: scroll;
  height: 100%;
  padding: 15px;
  padding-left: 25px;
}

.search-term {
  color: black;
  text-decoration: none;
  position: relative;
}

.search-term:hover {
  text-decoration: underline;
}

.phrases-canvas {
  height: 200vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.playlist {
  border: none;
  width: 100%;
  height: 100%;
}


.article {
  margin-bottom: 30px;
}

.article p {
  /* line-height: 0.4em; */
  margin: 5px;
}


.article h3 {
  text-decoration: underline;
  margin: 0px;
  margin-left: 5px;
}


.collapsible-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
  margin-left: 20px;
}

.collapsible-content p {
  margin: 10px;
}


.collapsible-content span {
  font-style: italic;
}

.collapsible-content h4 {
  cursor: pointer;
}

.collapsible {
  cursor: pointer;
}

.press-image {
  width: 100%;
  cursor: pointer;
}

.caption {
  height: 0px;
  overflow: hidden;
  transition: height 0.5s;
  margin-top: -3px;
}

.resource-desc {
  text-indent: -15px;
  margin-left: 15px;
}

.resource-desc:first-line {
  text-indent: 0px;
}

.fa-play {
  display: inline;
  font-size: 0.9em;
}

.center-div {
  display: flex;
  height: 100%;
  align-items: center;
}

.ark-image {
  width: 100%;
  padding: 0px;
  margin: 0px;
}

#video-container {
  display: none;
  position: -webkit-sticky;
  position: sticky;
  top: -15px;
  height: 0px;
}

#video-close {
  cursor: pointer;
  position: absolute;
  width: 50px;
  height: 50px;
  right: 0;
  top: 0;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50px 50px;
}

#video-player {
  height: 100%;
  width: 100%;
  background-color: #000c;
}

.load-video {
  cursor: pointer;
  text-decoration: underline;
}

#algorithm-diagram {
  height: 100%;
  /* width: 80%; */
  /* padding-top: 56.25%; */
  /* width: calc(856px/735px*100%); */
  cursor: crosshair;
}

.algorithm-vid {
  width: 80%;
}

.algorithm-img {
  width: 100%;
}

.algorithm-vid-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
